import styled, { css } from "styled-components";

export const Balance = styled.section`
  // COMMON
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;

    & > svg {
      width: 24px;
      fill: ${theme.palette.neutral.black};
      cursor: pointer;
    }
  `}

  // DESKTOP
  ${({ theme }) =>
    theme.breakpoints.desktop(`  
      width: 100%;
      padding: ${theme.spacings.squished.lg};
      background-color: ${theme.palette.neutral.white};
      border-radius: ${theme.borderRadius.sm};
      border: 1px solid ${theme.palette.neutral.lighter};
  `)}

  // MOBILE
  ${({ theme }) =>
    theme.breakpoints.mobile(`  
      padding: ${theme.spacings.inset.md};
      background-color: ${theme.palette.primary.lighter};
  `)}
`;

export const BalanceTitle = styled.h4`
  // COMMON
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.regular};
    min-width: max-content;
  `}

  // DESKTOP
  ${({ theme }) =>
    theme.breakpoints.desktop(`  
      font-size: ${theme.fontSizes.xs};
  `)}

  // MOBILE
  ${({ theme }) =>
    theme.breakpoints.mobile(`  
      font-size: ${theme.fontSizes.x2s};
  `)}
`;

export const AmountContainer = styled.div`
  // COMMON
  display: flex;
  justify-content: space-between;
  gap: 8px;

  & > p {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  // DESKTOP
  ${({ theme }) =>
    theme.breakpoints.desktop(`  
      flex-direction: column;
      p {
        justify-content: flex-start;
        align-items: center;
        gap: ${theme.spacings.inline.x2s};
      }
  `)}

  // MOBILE
  ${({ theme }) =>
    theme.breakpoints.mobile(`  
      align-items: center;
      p {
        align-items: flex-start;
        gap: ${theme.spacings.inline.x3s};
      }
  `)}
`;

export const AmountCurrency = styled.small`
  // COMMON
  font-size: ${({ theme }) => theme.fontSizes.xs};
  height: max-content;

  // DESKTOP
  ${({ theme }) =>
    theme.breakpoints.desktop(`  
      font-weight: ${theme.fontWeights.bold};
      color: ${theme.palette.primary.main};
  `)}

  // MOBILE
  ${({ theme }) =>
    theme.breakpoints.mobile(`  
      font-size: ${theme.fontSizes.xs};
      line-height: ${theme.lineHeights.sm};
      font-weight: ${theme.fontWeights.bold};
      color: ${theme.palette.neutral.darker};
  `)}
`;

export const AmountValue = styled.b`
  // COMMON
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  // DESKTOP
  ${({ theme }) =>
    theme.breakpoints.desktop(`  
      font-size: ${theme.fontSizes.lg};
      color: ${theme.palette.primary.main};
  `)}

  // MOBILE
  ${({ theme }) =>
    theme.breakpoints.mobile(`  
      font-size: ${theme.fontSizes.xs};
      line-height: ${theme.lineHeights.sm};
      color: ${theme.palette.neutral.darker};
  `)}
`;

export const BtnContainer = styled.div`
  margin: auto 0;
  color: ${({ theme }) => theme.palette.neutral.darkest};

  cursor: pointer;

  & > div {
    & > button {
      background-color: transparent !important;
      width: auto;
      height: auto;
      & > svg {
        width: 24px !important;
        height: auto;
        & > path {
          fill: ${({ theme }) => theme.palette.neutral.black} !important;
        }
      }
    }
  }
`;

export const Error = styled(Balance)`
  padding: 2rem 1rem;
`;
