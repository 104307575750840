import React from "react";
import { Button } from "@hyperlocal/vital";
import Numeral from "numeral";
import * as Styles from "./styles";
import * as Types from "./types";
import "numeral/locales/pt-br";
import Icon from "@hyperlocal/vital-icons";
import { useGetBalance } from "../../services/use-get-balance";

Numeral.locale("pt-br");

export const Balance: React.FC<Types.IBalance> = () => {
  const [pwdShow, setPwdShow] = React.useState(false);

  const { data, isError, refetch } = useGetBalance();

  const togglePwdShowState = () => {
    setPwdShow(!pwdShow);
  };

  if (isError) {
    return (
      <Styles.Error>
        <p>
          Desculpe, ocorreu um erro. Por favor, tente <br />
          novamente mais tarde
        </p>

        <Button onClick={() => refetch()} variant="link">
          Recarregar
        </Button>
      </Styles.Error>
    );
  }

  return (
    <Styles.Balance>
      <Styles.AmountContainer>
        <Styles.BalanceTitle>Saldo em conta</Styles.BalanceTitle>
        <p>
          <Styles.AmountCurrency>R$</Styles.AmountCurrency>

          <Styles.AmountValue>
            {pwdShow ? Numeral(data).format(" ,000,000.00") : "••••••"}
          </Styles.AmountValue>
        </p>
      </Styles.AmountContainer>

      <Styles.BtnContainer onClick={togglePwdShowState}>
        <Icon
          name={pwdShow ? "ComputersEye" : "ComputersEyeSlash"}
          fill="#000000"
          width="24"
          height="24"
        />
      </Styles.BtnContainer>
    </Styles.Balance>
  );
};

export default Balance;
