import React from "react";
import Numeral from "numeral";
import * as S from "./styles";
import * as Types from "./types";
import "numeral/locales/pt-br";
import { Button, IconButton, Skeleton } from "@hyperlocal/vital";
import { useGetBalance } from "../../services/use-get-balance";

Numeral.locale("pt-br");

const BalanceDashboard: React.FC<Types.IBalance> = () => {
  const [pwdShow, setPwdShow] = React.useState(false);

  const { data, isLoading, isError, refetch } = useGetBalance();

  const togglePwdShowState = () => {
    if (isLoading) return;

    setPwdShow(!pwdShow);
  };

  if (isError) {
    return (
      <S.Error>
        <p>Desculpe, ocorreu um erro. Por favor, tente novamente mais tarde</p>

        <Button variant="link" onClick={() => refetch()}>
          Recarregar
        </Button>
      </S.Error>
    );
  }
  return (
    <S.Balance>
      <S.BalanceTitle className="mobile-title">Saldo</S.BalanceTitle>
      <S.AmountContainer>
        <S.BalanceTitle className="desktop-title">
          Saldo em conta
        </S.BalanceTitle>

        <S.AmountValueContainer>
          <S.AmountCurrency>R$</S.AmountCurrency>

          {!isLoading || !isError ? (
            <S.AmountValue>
              {pwdShow ? Numeral(data).format(" ,000,000.00") : "••••••"}
            </S.AmountValue>
          ) : (
            <Skeleton width="222px" height="40px" />
          )}
        </S.AmountValueContainer>
        <S.BtnContainer className="mobile-icon">
          <IconButton
            data-test="toggleBalance"
            variant="primary"
            onClick={togglePwdShowState}
            disabled={isLoading}
            icon={pwdShow ? "ComputersEye" : "ComputersEyeSlash"}
          />
        </S.BtnContainer>
      </S.AmountContainer>
      <S.BtnContainer className="desktop-icon">
        <IconButton
          data-test="toggleBalance"
          variant="primary"
          onClick={togglePwdShowState}
          disabled={isLoading}
          icon={pwdShow ? "ComputersEye" : "ComputersEyeSlash"}
        />
      </S.BtnContainer>
    </S.Balance>
  );
};

export default BalanceDashboard;
