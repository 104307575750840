import { useQuery } from "@tanstack/react-query";
import { queries, requestBalance, useAccountStore } from "../utils";

const { balanceKeys } = queries;

export const useGetBalance = () => {
  const { currentAccountId } = useAccountStore();

  return useQuery({
    queryKey: balanceKeys.getBalance({ accountId: currentAccountId }),
    queryFn: () => requestBalance(),
    select: (data) => data.Balance,
  });
};
