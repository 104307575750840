import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import App from "./App";

const lifeCycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary() {
    // Customize the root error boundary for your micro-frontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifeCycles;
