import Icon from "@hyperlocal/vital-icons";
import styled, { css } from "styled-components";

export const Balance = styled.section`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;

  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
  `}

  ${({ theme }) =>
    theme.breakpoints.desktop(`
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
  `)}
`;

export const BalanceTitle = styled.h4`
  display: none;

  ${({ theme }) => css`
    font-size: ${theme.fontSizes.x2s};
    font-weight: ${theme.fontWeights.medium};
    line-height: ${theme.spacings.inline.xs};
  `}

  ${({ theme }) =>
    theme.breakpoints.desktop(`
      &.desktop-title {
        font-size: ${theme.fontSizes.xs}; 
        display: block;
      }
  `)}

  ${({ theme }) =>
    theme.breakpoints.mobile(`
      &.mobile-title {
        display: block;
        color: ${theme.palette.neutral.darkest};
      }
  `)}

${({ theme }) =>
    theme.breakpoints.tablet(`
      &.mobile-title {
        display: block;
      }
  `)}
`;

export const AmountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  ${({ theme: { breakpoints, spacings } }) =>
    breakpoints.desktop(`   
      flex-direction: column; 
      justify-content: flex-start;
      align-items: flex-start;
      gap: ${spacings.stack.nano}
  `)}
`;

export const AmountValueContainer = styled.div`
  display: flex;
`;

export const AmountCurrency = styled.small`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    color: ${theme.palette.neutral.darkest};
  `}

  height: max-content;
  margin: auto 0px;

  ${({ theme }) =>
    theme.breakpoints.desktop(`  
      color: ${theme.palette.primary.main};
  `)}
`;

export const AmountValue = styled.b`
  ${({ theme }) => css`
    width: 222px;
    font-size: ${theme.fontSizes.lg};
    line-height: ${theme.lineHeights.sm};
    font-weight: ${theme.fontWeights.bold};

    color: ${theme.palette.primary.main};
    margin: 0px ${theme.spacings.inline.x2s};
  `}

  ${({ theme }) =>
    theme.breakpoints.desktop(`  
      width: auto;
  `)}
`;

export const EyeIcon = styled(Icon)`
  width: 24px;
  fill: ${({ theme }) => theme.palette.neutral.black};
  cursor: pointer;

  display: none;

  ${({ theme }) =>
    theme.breakpoints.desktop(`
      &.desktop-icon {        
        display: block;
      }
  `)}

  ${({ theme }) =>
    theme.breakpoints.mobile(`
      &.mobile-icon {
        display: block;
      }
  `)} 
  
  ${({ theme }) =>
    theme.breakpoints.tablet(`
      &.mobile-icon {
        display: block;
      }
  `)}
`;

export const BtnContainer = styled.div`
  display: none;

  margin: auto 0;
  & > div {
    & > button {
      background-color: transparent !important;
      width: auto;
      height: auto;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }

      & > svg {
        width: 24px !important;
        height: auto;
        & > path {
          fill: ${({ theme }) => theme.palette.neutral.black} !important;
        }
      }
    }
  }

  ${({ theme }) =>
    theme.breakpoints.mobile(`
      &.mobile-icon {
        display: block;
      }
  `)}
  ${({ theme }) =>
    theme.breakpoints.tablet(`
      &.mobile-icon {
        display: block;
      }
  `)}

  ${({ theme }) =>
    theme.breakpoints.desktop(`
      &.desktop-icon {        
        display: block;
      }
`)}
`;

export const Error = styled.section`
  width: 100%;

  display: flex;
  justify-content: center;
`;
