/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { getVertical, themeProvider } from "@hyperlocal/banking-utility";
import { Provider } from "@hyperlocal/vital";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Routes } from "./Routes";
import { globalQueryClient } from "./utils";

function App() {
  const theme = getVertical();

  return (
    <Provider theme={theme}>
      <ThemeProvider theme={themeProvider}>
        <BrowserRouter>
          <QueryClientProvider client={globalQueryClient}>
            {process.env.NODE_ENV === "development" && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
            <Routes />
          </QueryClientProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
